<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import _ from "lodash";
import RelationshipSearch from "@/components/iam/RelationshipSearch.vue";
import { iamType } from "@/services/api/iam.api";
import { mdiAccount, mdiAccountGroup, mdiApps, mdiClipboardTextOutline, mdiScriptTextPlayOutline, mdiScriptTextKeyOutline, mdiShape } from "@mdi/js";

const emits = defineEmits(["add-relationship", "input"]);

const props = defineProps({
  value: { default: false },
  domainType: undefined,
  selectionType: undefined,
});

const currentStep = ref(undefined);
const steps = ref([]);

const showDrawer = ref(false);
const searchRefs = ref();

const assignText = computed(() => {
  switch (props.domainType) {
    case iamType.User:
      return "Toewijzen aan gebruiker";
    case iamType.UserGroup:
      return "Toewijzen aan groep";
    case iamType.Resource:
      return "Toewijzen aan resource";
    case iamType.Project:
      return "Toewijzen aan project";
    case iamType.Role:
      return "Toewijzen aan rol";
    case iamType.Permission:
      return "Toewijzen aan recht";
    case iamType.PermissionCategory:
      return "Toewijzen aan rechten categorie";
    default:
      return "";
  }
});

watch(
  () => props.value,
  () => {
    console.log("RelationshipSelector value", props.value);
    showDrawer.value = props.value;
  },
  { immediate: true }
);

watch(
  () => showDrawer.value,
  () => {
    console.log("RelationshipSelector showDrawer", showDrawer.value);
    //eerste keer data ophalen
    if (showDrawer.value === true) {
      currentStep.value = 1;
    }
    emits("input", showDrawer.value);
  }
);

watch(
  () => props.selectionType,
  () => {
    switch (props.selectionType) {
      case iamType.User:
        steps.value = [{ type: iamType.User, selectedItems: [] }];
        switch (props.domainType) {
          case iamType.Resource:
            steps.value.push({ type: iamType.Project, selectedItems: [] });
            steps.value.push({ type: iamType.Role, selectedItems: [] });
            break;
          case iamType.Project:
            steps.value.push({ type: iamType.Resource, selectedItems: [] });
            steps.value.push({ type: iamType.Role, selectedItems: [] });
            break;
          case iamType.Role:
            steps.value.push({ type: iamType.Resource, selectedItems: [] });
            steps.value.push({ type: iamType.Project, selectedItems: [] });
            break;
        }
        break;
      case iamType.UserGroup:
        steps.value = [{ type: iamType.UserGroup, selectedItems: [] }];
        switch (props.domainType) {
          case iamType.Resource:
            steps.value.push({ type: iamType.Project, selectedItems: [] });
            steps.value.push({ type: iamType.Role, selectedItems: [] });
            break;
          case iamType.Project:
            steps.value.push({ type: iamType.Resource, selectedItems: [] });
            steps.value.push({ type: iamType.Role, selectedItems: [] });
            break;
          case iamType.Role:
            steps.value.push({ type: iamType.Resource, selectedItems: [] });
            steps.value.push({ type: iamType.Project, selectedItems: [] });
            break;
        }
        break;
      case iamType.Resource:
        steps.value = [{ type: iamType.Resource, selectedItems: [] }];
        if (props.domainType != iamType.PermissionCategory) {
          steps.value.push({ type: iamType.Project, selectedItems: [] });
          steps.value.push({ type: iamType.Role, selectedItems: [] });
        }
        break;
      case iamType.Project:
        steps.value = [
          { type: iamType.Project, selectedItems: [] },
          { type: iamType.Resource, selectedItems: [] },
          { type: iamType.Role, selectedItems: [] },
        ];
        break;
      case iamType.Role:
        steps.value = [{ type: iamType.Role, selectedItems: [] }];
        if (props.domainType != iamType.Permission) {
          steps.value.push({ type: iamType.Resource, selectedItems: [] });
          steps.value.push({ type: iamType.Project, selectedItems: [] });
        }
        break;
      case iamType.Permission:
        steps.value = [{ type: iamType.Permission, selectedItems: [] }];
        break;
      case iamType.PermissionCategory:
        steps.value = [{ type: iamType.PermissionCategory, selectedItems: [] }];
        break;

      default:
        return undefined;
    }
  },
  { immediate: true }
);

function selectText(type) {
  switch (type) {
    case iamType.User:
      return "Selecteer gebruikers";
    case iamType.UserGroup:
      return "Selecteer groepen";
    case iamType.Resource:
      return "Selecteer resources";
    case iamType.Project:
      return "Selecteer projecten";
    case iamType.Role:
      return "Selecteer rollen";
    case iamType.Permission:
      return "Selecteer rechten";
    case iamType.PermissionCategory:
      return "Selecteer rechten categorie";
    default:
      return "";
  }
}

function listIcon(type) {
  switch (type) {
    case iamType.User:
      return mdiAccount;
    case iamType.UserGroup:
      return mdiAccountGroup;
    case iamType.Resource:
      return mdiApps;
    case iamType.Project:
      return mdiClipboardTextOutline;
    case iamType.Role:
      return mdiScriptTextPlayOutline;
    case iamType.Permission:
      return mdiScriptTextKeyOutline;
    case iamType.PermissionCategory:
      return mdiShape;

    default:
      return undefined;
  }
}

function onSelected(index, items) {
  console.log("RelationshipSelector onSelected", index, items);
  steps.value[index].selectedItems = [...items];
}

function stepIcon(length) {
  return length > 0 ? "$vuetify.icons.complete" : "$vuetify.icons.edit";
}

function stepCompleted(length, step) {
  return length > 0 || currentStep.value == step;
}

function nextStep() {
  let next = 0;
  while (next < steps.value.length && steps.value[next].selectedItems.length > 0) {
    next++;
  }
  next++;
  //console.log("RelationshipSelector nextStep ", next);
  currentStep.value = next;
}

function assignValid(step) {
  //alleen op laatste stap de andere stappen error geven of niet
  return currentStep.value != steps.value.length + 1 || steps.value[step - 1].selectedItems.length > 0;
}

function isAssignInvalid() {
  const found = _.find(steps.value, function (step) {
    return step.selectedItems == 0;
  });
  return !!found;
}

function onAssignSelection() {
  let selection = {};
  steps.value.forEach((step) => {
    const ids = _.map(step.selectedItems, function (item) {
      return item.id;
    });
    switch (step.type) {
      case iamType.User:
        selection.users = ids;
        break;
      case iamType.UserGroup:
        selection.userGroups = ids;
        break;
      case iamType.Resource:
        selection.resources = ids;
        break;
      case iamType.Project:
        selection.projects = ids;
        break;
      case iamType.Role:
        selection.roles = ids;
        break;
      case iamType.Permission:
        selection.permissions = ids;
        break;
      case iamType.PermissionCategory:
        selection.permissionCategories = ids;
        break;
    }
  });
  //console.log("RelationshipSelector onAssignSelection steps ", steps.value);
  emits("add-relationship", selection);

  //console.log("RelationshipSelector resetSearch ");
  showDrawer.value = false;
  currentStep.value = 0;
  searchRefs.value.forEach((el) => el.resetSearch());
}
</script>

<template>
  <v-navigation-drawer v-model="showDrawer" right width="400" temporary absolute>
    <v-container fluid>
      <header class="mb-2">
        <v-row class="align-center">
          <v-col cols="auto"> Creëer relatie </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn icon @click.stop="showDrawer = false">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </header>
      <v-stepper v-model="currentStep" vertical non-linear elevation="0" class="relationStepper">
        <template v-for="(item, index) in steps">
          <v-stepper-step
            :step="index + 1"
            :key="`${index}-step`"
            editable
            :edit-icon="stepIcon(item.selectedItems.length)"
            :complete="stepCompleted(item.selectedItems.length, index + 1)"
            :rules="[() => assignValid(index + 1)]"
          >
            {{ selectText(item.type) }}
            <small v-show="item.selectedItems.length > 0">Geselecteerd: {{ item.selectedItems.length }}</small>
          </v-stepper-step>

          <v-stepper-content :step="index + 1" :key="`${index}-content`">
            <relationship-search ref="searchRefs" :type="item.type" @on-selected="onSelected(index, $event)" :open="currentStep == index + 1"> </relationship-search>
            <v-btn color="primary" @click="nextStep()" :disabled="item.selectedItems.length == 0"> Ok </v-btn>
          </v-stepper-content>
        </template>

        <v-stepper-step :step="steps.length + 1" editable>
          {{ assignText }}
        </v-stepper-step>
        <v-stepper-content :step="steps.length + 1">
          <div v-for="(step, index) in steps" :key="`${index}-assign`">
            <v-row v-if="step.selectedItems.length > 0">
              <v-col cols="12">
                <v-list two-line>
                  <template v-for="(listItem, listIndex) in step.selectedItems">
                    <v-list-item class="relationSelectItem" :key="`${index}-${listIndex}-assignlist`">
                      <v-list-item-icon :key="`${index}-${listIndex}-icon`" class="mr-2">
                        <v-icon>{{ listIcon(step.type) }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ listItem.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ listItem.description }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`${index}-${listIndex}-divider`"></v-divider>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12">
              <v-btn class="primary" @click="onAssignSelection()" :disabled="isAssignInvalid()"> Toewijzen </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-container>
  </v-navigation-drawer>
</template>
