<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import _ from "lodash";
import { getUsers, getUserGroups, getResources, getProjects, getRoles, getPermissions, getPermissionCategories, iamType } from "@/services/api/iam.api";

const emits = defineEmits(["on-selected"]);
defineExpose({ resetSearch });

const props = defineProps({
  type: undefined,
  open: undefined,
});

const searchItems = ref([]);
const searchText = ref(undefined);
const selectedItems = ref([]);

const selectedText = computed(() => {
  switch (props.type) {
    case iamType.User:
      return "Geselecteerde gebruikers";
    case iamType.UserGroup:
      return "Geselecteerde groepen";
    case iamType.Resource:
      return "Geselecteerde resources";
    case iamType.Project:
      return "Geselecteerde projecten";
    case iamType.Role:
      return "Geselecteerde rollen";
    case iamType.Permission:
      return "Geselecteerde rechten";
    case iamType.PermissionCategory:
      return "Geselecteerde rechten categorieen";
    default:
      return "";
  }
});

watch(
  () => searchText.value,
  () => {
    //console.log("RelationshipSearch onSearch: ", searchText.value);
    if (searchText.value !== undefined) {
      doSearch(searchText.value);
    }
  }
);

watch(
  () => selectedItems.value,
  () => {
    emits("on-selected", selectedItems.value);
  },
  { deep: true }
);

watch(
  () => props.open,
  () => {
    //console.log("RelationshipSearch props.open: ", props.open);
    if (props.open === true) {
      if (!searchText.value) {
        searchText.value = "";
      }
    }
  }
);

function resetSearch() {
  //console.log("RelationshipSearch resetSearch");
  searchText.value = undefined;
  searchItems.value = [];
  selectedItems.value = [];
}

async function doSearch(searchText) {
  //console.log("RelationshipSearch doSearch: ", searchText.value);
  let filter = [];
  let response = undefined;
  switch (props.type) {
    case iamType.User:
      filter = [
        {
          fieldName: "name",
          contains: searchText,
        },
        {
          fieldName: "department",
          contains: searchText,
        },
        {
          fieldName: "email",
          contains: searchText,
        },
        {
          fieldName: "firstname",
          contains: searchText,
        },
        {
          fieldName: "lastname",
          contains: searchText,
        },
        {
          fieldName: "middlename",
          contains: searchText,
        },
        {
          fieldName: "function",
          contains: searchText,
        },
      ];

      response = await getUsers(0, 15, undefined, undefined, filter, false, true);
      //console.log("RelationshipSearch getUsers: ", response, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.id, name: item.name, description: `${item.department} (${item.email})` };
        });
      }
      break;
    case iamType.UserGroup:
      filter = [
        {
          fieldName: "Name",
          contains: searchText,
        },
        {
          fieldName: "Description",
          contains: searchText,
        },
      ];

      response = await getUserGroups(0, 15, undefined, undefined, undefined, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.id, name: item.name, description: item.description };
        });
      }
      break;
    case iamType.Resource:
      filter = [
        {
          fieldName: "Reference",
          contains: searchText,
        },
        {
          fieldName: "Description",
          contains: searchText,
        },
      ];

      response = await getResources(0, 15, undefined, undefined, undefined, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.id, name: item.reference, description: item.description };
        });
      }
      break;
    case iamType.Project:
      filter = [
        {
          fieldName: "Name",
          contains: searchText,
        },
        {
          fieldName: "Description",
          contains: searchText,
        },
      ];

      response = await getProjects(0, 15, undefined, undefined, undefined, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.id, name: item.name, description: item.description };
        });
      }
      break;
    case iamType.Role:
      filter = [
        {
          fieldName: "Name",
          contains: searchText,
        },
        {
          fieldName: "Description",
          contains: searchText,
        },
      ];

      response = await getRoles(0, 15, undefined, undefined, undefined, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.id, name: item.name, description: item.description };
        });
      }
      break;
    case iamType.Permission:
      filter = [
        {
          fieldName: "Name",
          contains: searchText,
        },
        {
          fieldName: "Description",
          contains: searchText,
        },
      ];

      response = await getPermissions(0, 15, undefined, undefined, undefined, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.key, name: item.name, description: item.description };
        });
      }
      break;
    case iamType.PermissionCategory:
      filter = [
        {
          fieldName: "Name",
          contains: searchText,
        },
        {
          fieldName: "Description",
          contains: searchText,
        },
      ];

      response = await getPermissionCategories(0, 15, undefined, undefined, undefined, filter);
      if (response && !response.error) {
        searchItems.value = _.map(response.data.data, function (item) {
          return { id: item.id, name: item.name, description: item.description };
        });
      }
      break;
  }
  // console.log("RelationshipSearch searchItems: ", searchItems.value.length, searchItems.value);
}

function addSelected(item) {
  console.log("RelationshipSearch addSelected: ", item);
  if (!isAlreadySelected(item.id)) {
    selectedItems.value.push(item);
  }
}

function removeSelected(id) {
  console.log("RelationshipSearch removeSelected: ", id);
  _.remove(selectedItems.value, { id: id });
  //vue does not respond to remove therefore:
  selectedItems.value = [...selectedItems.value];
}

function isAlreadySelected(id) {
  return !!_.find(selectedItems.value, ["id", id]);
}
</script>

<template>
  <div>
    <v-text-field prepend-inner-icon="mdi-magnify" clearable hide-details clear-icon="mdi-close-circle" solo v-model="searchText"></v-text-field>
    <v-row class="align-center" v-if="searchItems.length == 0">
      <v-col cols="12"> Geen resultaten gevonden! </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="searchItems.length > 0">
        <v-list two-line>
          <template v-for="(item, index) in searchItems">
            <v-list-item class="relationSelectItem" :key="index" @click="addSelected(item)" :disabled="isAlreadySelected(item.id)">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`search-div-${index}`"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-row class="align-center mt-5" v-if="selectedItems.length > 0">
      <v-col cols="12">
        {{ selectedText }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-list two-line v-if="selectedItems.length > 0">
          <template v-for="(item, index) in selectedItems">
            <v-list-item class="relationSelectItem" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action :key="item.id">
                <v-btn icon @click="removeSelected(item.id)">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`sel-div-${index}`"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>
